import type { FunctionComponent, ReactElement, ReactNode } from 'react'
import React, { useState } from 'react'
import { Modal, SlideImage, ZoomImageHint } from '@which/seatbelt'

import { isTouchDevice } from '../../utils/is-touch-device'
import styles from './ZoomImageModal.module.scss'

export const ZoomImageModal: FunctionComponent<Props> = ({ zoomWidth = 2160, children }) => {
  const touchDevice = isTouchDevice()
  const zoomHeight = zoomWidth * 0.5 // 2:1 aspect ratio
  const [modalOpen, SetModalOpen] = useState(false)
  const [modalImageObj, setModalImageObj] = useState<ModalImageObj>({
    src: '',
    alt: '',
    zoomWidth,
    zoomHeight,
  })

  const closeModalHandler = () => {
    SetModalOpen(false)
  }

  const imageClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement
    const imageWrapper = target.closest('[data-testid="body-image-wrapper"]')

    if (!imageWrapper || touchDevice) {
      return
    }

    const image = imageWrapper.querySelector('img')

    if (image) {
      const { src, alt } = image

      setModalImageObj((prevState) => ({ ...prevState, src, alt }))
      SetModalOpen(true)
    }
  }

  /* v8 ignore next */
  const nullCallback = () => null

  return (
    <>
      {!touchDevice && modalOpen && (
        <div data-testid="zoom-image-modal" className={styles.zoomImageModal}>
          <Modal closeModal={closeModalHandler}>
            <div className={styles.slideWrapper}>
              <div className={styles.imageWrapper}>
                <SlideImage
                  src={modalImageObj.src}
                  zoomSrc={modalImageObj.src}
                  zoomWidth={modalImageObj.zoomWidth}
                  zoomHeight={modalImageObj.zoomHeight}
                  alt={modalImageObj.alt}
                  setPortalConfig={nullCallback}
                  carouselBgColor={'grey'}
                />
              </div>
            </div>
          </Modal>
        </div>
      )}
      <div onClick={imageClickHandler}>
        {!touchDevice ? makeImagesZoomable(children) : children}
      </div>
    </>
  )
}

///////// IMPLEMENTATION /////////

const hasChildImage = (element: ReactElement) => {
  return element.props?.children?.[0]?.props?.src
}

const makeImagesZoomable = (children: ReactNode) => {
  return React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child
    }

    const childImage = hasChildImage(child)

    return React.cloneElement(child, {
      ...child.props,
      ...(childImage && { 'data-testid': 'body-image-wrapper' }),
      ...(childImage && { className: styles.bodyImageWrapper }),
      children: childImage
        ? [
            <div data-testid="zoom-image-button-wrapper" key="zoom-modal-button">
              <button
                data-testid="zoom-image-button"
                className={styles.bodyImageButton}
                aria-label="click for larger image"
              />
              <ZoomImageHint />
            </div>,
            makeImagesZoomable(child.props.children),
          ]
        : makeImagesZoomable(child.props.children),
    })
  })
}

type Props = {
  children?: ReactNode
  zoomWidth?: number
}

type ModalImageObj = {
  src: string
  alt: string
  zoomWidth: number
  zoomHeight: number
}
